// VideoTab.js
import React from "react";
import Video from "../userDetailComponents/Video/Video";
import SearchIcon from "../../assets/images/search-round.png";

import Menu from "../../assets/images/active_menu.png";
import { connect } from "react-redux";
import { IconPath } from "../../constants/IconPath";

const VideoTab = (props) => {
  return (
    <div>
      <div className="video-tabe-wrap">
        <div class="menu-item-container pt-4 p-0">
          <div>
            <h5 class="common-title avenir-bold">Videos</h5>
          </div>
          <div class="user-details-pop-up-side ">
            <IconPath.Search style={{ color: 'black', cursor: 'pointer' }} />
            <img src={Menu} alt="menu" />
          </div>
        </div>
        {props?.userDetailList?.data?.content?.query?.count === 0 && (
          <div className="row">
            <div className="text-center">
              <h4>No content uploaded</h4>
            </div>
          </div>
        )}
        <Video data={props?.userDetailList?.data?.content?.query?.rows} /> :
      </div>
    </div>
  );
};

VideoTab.propTypes = {};
VideoTab.defaultProps = {};

const actionCreators = {};

export default connect(
  ({ user, content }) => ({
    selectedUserData: user.selectedUser,
    selectedUserLoading: user.selectedUserLoading,
    userProfileModal: user.userProfileModalToggle,
    modelToggleData: user.modelToggle,
    userDetailList: user.userDetail,
    userDetailLoading: user.userDetailLoading,
  }),
  actionCreators
)(VideoTab);
