import {
    TimerOutlined,
    Search,
    Close,
    ThumbDown,
    ThumbUp
} from "@mui/icons-material";


export const IconPath = {
    TimerOutlined,
    Search,
    Close,
    ThumbDown,
    ThumbUp
}