import React, { useState, useEffect } from "react";
import "./Dialog.scss";
import "../../pages/userManagement/userManagementList/userManagementList.scss";
import Dialog from "@mui/material/Dialog";
import VectorIcon from "../../assets/images/Vector1.png";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import {
  modelToggle,
  sendEmail,
  UpdatePassword,
  deleteUser,
  showDetail,
  deleteAssessmentUser,
  getAssessmentUserDetails,
} from "../../redux/action/user";
import { disapproveContent, reworkContent } from "../../redux/action/content";
import { IconPath } from "../../constants/IconPath";

const DialogView = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [userName, setUserName] = useState("");
  const [age, setAge] = useState("");
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfiormPassword] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (localStorage.getItem("userAssessmentId")) {
      props.getAssessmentUserDetails(localStorage.getItem("userAssessmentId"));
    }
  }, []);


  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setLoading(false);
    setUserName('');
    setEmail('');
    props.modelToggle(false);
  };

  const disAppruve = () => {
    props.disapproveContent(id);
    handleClose();
    // navigate('/content');
  };

  const requestToRework = () => {
    props.reworkContent(id);
    handleClose();
  };

  const deleteUserSoft = async () => {
    if (props.role == 1) {
      const res = await props.deleteAssessmentUser();
      if (props.deleteUserAssessment === "failed") {
        toast("Failed to Delete User");
      } else {
        toast(res?.message || "User Deleted Successfully");
      }
      props.showDetail(false);
    } else {
      props.deleteUser(props?.selectedUser?.contentUser?.UserID);
    }
    handleClose();
  };

  const closeTostSendEmail = () => {
    handleClose();
    toast("Email send successfully");
    setEmail("");
    setUserName("");
  };

  const SendInvitation = () => {
    if (userName.length <= 0) {
      toast("Please enter user name!");
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(email)) {
      toast("Invalid Email");
      return
    } else if (email.length <= 0) {
      toast("Email can not be blank!");
      return
    } else {
      setLoading(true)
      props.sendEmail(email, userName, closeTostSendEmail, props.role, handleSendEmailCompletion);
    }
  };

  const handleSendEmailCompletion = () => {
    setLoading(false);
  };

  const changePassword = () => {
    if (currentPassword.length <= 4) {
      toast("Password length must be more then 4 charecters!!");
      return false;
    } else if (confirmPassword.length <= 4) {
      toast("Password length must be more then 4 charecters!!");
      return false;
    } else if (newPassword.length <= 4) {
      toast("Password length must be more then 4 charecters!!");
      return false;
    } else if (newPassword !== confirmPassword) {
      toast("Password does not match!");
      return false;
    } else if (
      newPassword === confirmPassword &&
      currentPassword === newPassword
    ) {
      toast("this password already exists!");
      return false;
    } else {
      props.UpdatePassword(currentPassword, newPassword, confirmPassword);
      toast("Password changes successfull...");
      props.modelToggle(false);
      navigate("/");
      localStorage.setItem("authToken", null);
      localStorage.setItem("isLogin", false);
      localStorage.setItem("loginUser", null);
      setCurrentPassword("");
      setNewPassword("");
      setConfiormPassword("");
    }
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Dialog
      className="out-dialog-box"
      open={props?.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="dialog-container">
        {props?.modelToggleData?.modelName !== "remove_model" && (
          <div onClick={() => handleClose()} className="ab-close-btn">
            <img src={require("../../assets/images/close.png")} />
          </div>
        )}
        {props?.modelToggleData?.modelName === "createUser" && (
          <h5 className="model-title title-text">
            Create {props?.role === 2 ? "Admin" : props?.role === 3 ? "Teacher" : "User"}
          </h5>
        )}

        {props?.modelToggleData?.modelName === "change_password" && (
          <h5 className="model-title title-text">Change Password</h5>
        )}

        {props?.modelToggleData?.modelName === "edit_model" && (
          <h5 className="model-title title-text">Edit Profile</h5>
        )}

        {props?.modelToggleData?.modelName === "change_password" && (
          <div className="dialog-item">
            <div className="inner-dialog-item">
              <label className="model-label-text">CURRENT PASSWORD</label>
              <input
                type="password"
                defaultValue={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                value={currentPassword}
                className="input-dialog"
                placeholder="************"
              />
            </div>
            <div className="inner-dialog-item">
              <label className="model-label-text">NEW PASSWORD</label>
              <input
                type="password"
                className="input-dialog"
                placeholder="************"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
              />
            </div>
            <div className="inner-dialog-item" style={{ marginTop: "15px" }}>
              <label className="model-label-text">CONFIRM PASSWORD</label>
              <input
                type="password"
                className="input-dialog"
                placeholder="************"
                onChange={(e) => setConfiormPassword(e.target.value)}
                value={confirmPassword}
              />
            </div>

            <button
              className="send-message-btn"
              onClick={() => changePassword()}
            >
              <img src={require("../../assets/images/Vector.png")} />{" "}
              {props?.buttonLabel}
            </button>
          </div>
        )}
      </div>

      {props?.modelToggleData?.modelName === "createUser" && (
        <div className="dialog-item">
          <div className="inner-dialog-item">
            <label className="model-label-text">FULL NAME</label>
            <input
              type="text"
              autoFocus={true}
              placeholder="Full name"
              onChange={(e) => setUserName(e.target.value)}
              className="input-dialog"
            />
          </div>
          <div className="inner-dialog-item">
            <label className="model-label-text">EMAIL ADDRESS</label>
            <input
              type="text"
              className="input-dialog"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button className="send-message-btn" onClick={SendInvitation} disabled={loading}>
            {loading ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              <img src={VectorIcon} alt="Send Icon" style={{ width: 20, height: 20 }} />
            )}
            <span style={{ marginLeft: '8px' }}>{props?.buttonLabel}</span>
          </button>
        </div>
      )}

      {props?.modelToggleData?.modelName === "edit_model" && (
        <div className="dialog-item">
          <div className="inner-dialog-item">
            <label className="model-label-text">FULL NAME</label>
            <input
              type="text"
              autoFocus={true}
              placeholder="Full name"
              onChange={(e) => setUserName(e.target.value)}
              defaultValue={
                props?.currentUser?.data?.contentUser?.Fullname
                  ? props?.currentUser?.data?.contentUser?.Fullname
                  : "..."
              }
              className="input-dialog"
            />
          </div>
          <div className="inner-dialog-item">
            <label className="model-label-text">EMAIL ADDRESS</label>
            <input
              type="text"
              className="input-dialog"
              value={props?.currentUser?.data?.contentUser?.EmailID}
              placeholder="Email"
            />
          </div>

          <button className="send-message-btn">
            <img src={require("../../assets/images/Vector.png")} />{" "}
            {props?.buttonLabel}
          </button>
        </div>
      )}

      {props?.modelToggleData?.modelName === "add_thresol_value" && (
        <div className="dialog-container">
          <div onClick={() => handleClose()} className="ab-close-btn">
            <img src={require("../../assets/images/close.png")} />
          </div>
          <h5 className="model-title title-text">Approved content Threshold</h5>
          <div className="dialog-item">
            <div className="inner-dialog-item">
              <label className="model-label-text">
                SET THE THRESHOLD NUMBER
              </label>
              <input type="text" defaultValue={40} className="input-dialog" />
            </div>
            <div className="inner-dialog-item">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <p>
                      Do you want to Disable the subcategory after it's number
                      of content reaches to thresold value ?
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    />
                  </Grid>
                </Grid>
              </Box>
            </div>

            <button className="send-message-btn">+ ADD THRESHOLD VALUE</button>
          </div>
        </div>
      )}

      {props?.modelToggleData?.modelName === "set_duration" && (
        <div className="dialog-container">
          <div onClick={() => handleClose()} className="ab-close-btn">
            <img src={require("../../assets/images/close.png")} />
          </div>
          <h5 className="model-title title-text">Auto delete</h5>
          <div className="dialog-item">
            <div className="inner-dialog-item out-cust-drop">
              <label className="model-label-text">SET THE DURATION</label>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={age}
                  onChange={handleChange}
                  className={"cusDrop"}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>Enter the number</em>
                  </MenuItem>
                  <MenuItem value={1}>1 Month</MenuItem>
                  <MenuItem value={20}>3 Months</MenuItem>
                  <MenuItem value={30}>6 Months</MenuItem>
                  <MenuItem value={30}>1 Year</MenuItem>
                  <MenuItem value={30}>Node</MenuItem>
                </Select>
              </FormControl>
            </div>

            <button className="send-message-btn" style={{ marginTop: "230px" }}>
              <IconPath.TimerOutlined style={{ fontSize: '24px', color: 'white' }} />
              SET DURATION
            </button>
          </div>
        </div>
      )}

      {props?.modelToggleData?.modelName === "remove_model" && (
        <div className="dialog-container dialog-container-lg">
          <div className="dialog-item">
            <img src={require("../../assets/images/removeModelImage.png")} />
            <div className="subTitleText">
              <h3>Are you sure you want to remove the content?</h3>
              <div className="outerDiv">
                <Button
                  onClick={() => handleClose()}
                  variant="outlined"
                  className="btnOutline btnOutline-non-shadow"
                  sx={{
                    backgroundColor: "#fff",
                    borderColor: "#e6e6e6",
                    color: "#000",
                    marginRight: "10px",
                  }}
                >
                  NO, KEEP
                </Button>
                <Button
                  onClick={() => handleClose()}
                  className="btn btnOutline-non-shadow"
                  variant="contained"
                >
                  YES, REMOVE{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.modelToggleData?.modelName === "dis_approve" && (
        <div className="dialog-container dialog-container-lg">
          <div className="dialog-item" style={{ alignItems: "center" }}>
            <img src={require("../../assets/images/dis_appruve.png")} />
            <div className="subTitleText">
              <h3>
                Do you want to request rework on the content or Disapprove
                completely?
              </h3>
              <div>
                <Button
                  onClick={() => requestToRework()}
                  variant="outlined"
                  className="btnOutline btnOutline-non-shadow"
                  sx={{
                    backgroundColor: "#fff",
                    borderColor: "#e6e6e6",
                    color: "#000",
                    marginRight: "10px",
                  }}
                >
                  YES, REQUEST REWORK
                </Button>
                <Button
                  onClick={() => disAppruve()}
                  className="btn btnOutline-non-shadow"
                  variant="contained"
                >
                  NO, DISAPPROVE COMPLETELY{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.modelToggleData?.modelName === "delete_user" && (
        <div className="dialog-container dialog-container-lg">
          <div className="dialog-item" style={{ alignItems: "center" }}>
            <img src={require("../../assets/images/dis_appruve.png")} />
            <div className="subTitleText">
              <h3>
                Are you sure want to
                {props.role == 1 ? `${props.userAssessmentDetails?.Status === "A" ? " deactivate" : " activate"}` : " delete"} this user?
              </h3>
              <div>
                <Button
                  onClick={() => deleteUserSoft()}
                  variant="outlined"
                  className="btnOutline btnOutline-non-shadow"
                  sx={{
                    backgroundColor: "#fff",
                    borderColor: "#e6e6e6",
                    color: "#000",
                    marginRight: "10px",
                  }}
                  disabled={props.userDeleteLoading}
                >
                  YES, PLEASE
                </Button>
                <Button
                  onClick={() => handleClose()}
                  className="btn btnOutline-non-shadow"
                  variant="contained"
                  disabled={props.userDeleteLoading}
                >
                  NO, WAIT{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

DialogView.propTypes = {};
DialogView.defaultProps = {};

const actionCreators = {
  sendEmail,
  modelToggle,
  deleteUser,
  disapproveContent,
  UpdatePassword,
  reworkContent,
  deleteAssessmentUser,
  showDetail,
  getAssessmentUserDetails,
};

export default connect(
  ({ user, content }) => ({
    modelToggleData: user.modelToggle,
    contentDisappruved: content.contentDisappruved,
    changePasswordRes: user.changePassword,
    currentUser: user.currentUser,
    deleteUser: user.deleteUser,
    selectedUser: user.selectedUser,
    deleteUserAssessment: user.deleteUserAssessment,
    userAssessmentDetails: user.userAssessmentDetails,
  }),
  actionCreators
)(DialogView);

// export default connect(
//   ({ user, content }) => ({
//     selectedUserData: user.selectedUser,
//     selectedUserLoading: user.selectedUserLoading,
//     userProfileModal: user.userProfileModalToggle,
//     modelToggleData: user.modelToggle,
//     userDetailList: user.userDetail,
//     userDetailLoading: user.userDetailLoading,
//     userAssessmentDetails: user.userAssessmentDetails,
//   }),
//   actionCreators
// )(UserRemove);