import React, { useEffect, useState, useRef } from "react";
import "./createContent.scss";
import ListImg from "../../assets/images/content-list-img.png";
import BtnDot from "../../assets/images/btn-check.png";
import LeftBack from "../../assets/images/left-back-step.png";
import SearchIcon from "../../assets/images/search.png";
import { connect } from 'react-redux';
import { determinant, determinantCategory, determinantCategoryPractices, getTeacherList } from "../../redux/action/content";
import Hoc from "../../component/HOC";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Paper from "@mui/material/Paper";
import ModalVideo from 'react-modal-video';
import Grid from '@mui/material/Unstable_Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EyeGroup from "../../assets/images/eye-group.png";
import Star from "../../assets/images/star.svg";
import Calender from "../../assets/images/calender.svg";
import LinkTag from "../../component/LinkTag/LinkTag";
import { capitalizeFirstLowercaseRest } from "../../constants/constant";
import { experimentalStyled as styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { uploadAllKindOfFiles, createContentByAdmin } from "../../redux/action/content";
import TickCurve from "../../assets/images/tick-curve.png";

const CreateContent = (props) => {
  const navigate = useNavigate();
  const contenttype = localStorage.getItem('contenttype');
  const thumnil = localStorage.getItem('thumnil');

  const [selectedTeacher, setSelectedTeacher] = useState(0);
  const [activeDeterminant, setActiveDeterminant] = useState(0);
  const [activeDeteminantCategory, setActiveDeteminantCategory] = useState(0);
  const [activeDeteminantCategoryPractices, setActiveDeteminantCategoryPractices] = useState(0);
  const [activeUploadContentType, setUploadContentType] = useState(0);
  const [stepsLevels, setStepsLevel] = useState(
    [{
      step: 1,
      status: true,
      name: "Choose Teacher",
      index: 0
    },
    {
      step: 2,
      status: false,
      name: "Choose Determinants",
      index: 0
    },
    {
      step: 3,
      status: false,
      name: "Choose Content Type",
      index: 1
    },
    {
      step: 4,
      status: false,
      name: "Content Description",
      index: 2
    },
    {
      step: 5,
      status: false,
      name: "Content Preview",
      index: 3
    }]
  )
  const [currentStep, setCurrentStep] = useState(stepsLevels[0]);
  const [selectDeterminants, setSelectedDeteminants] = useState()
  const [videoModelToggle, setVideoModelToggle] = useState(false);
  const [contentUploadTypes, setContentUploadTypes] = useState(
    [
      {
        ID: 1,
        contentTitle: 'Video Content',
        contentType: '',
        description: 'Upload a video with description & thumbnail.',
        contentImage: 'video-play-Unselect.png',
        contentSelectImage: 'video-play-select.png'
      },
      {
        ID: 2,
        contentTitle: 'Infographic Content',
        contentType: '',
        description: 'Written content & upload thumnail.',
        contentImage: 'chart.png',
        contentSelectImage: 'chart-on.png'
      },
      {
        ID: 3,
        contentTitle: 'Text Content',
        contentType: '',
        description: 'Written content & upload thumnail.',
        contentImage: 'text.png',
        contentSelectImage: 'text-on.png'
      }
    ]
  )
  const [contentTitle, setContentTitle] = useState();
  const [description, setDescription] = useState();
  const [refLinkOne, setRefLinkOne] = useState();
  const [refLinkTwo, setRefLinkTwo] = useState();
  const [refLinkThree, setRefLinkThree] = useState();
  const [thumbnile, setThumbnile] = useState();
  const [selectedUserName, setSelectedUserName] = useState();
  const [selectedUserProfile, setSelectedUserProfile] = useState();
  const [category, setCategory] = useState();
  const [practices, setPractices] = useState();
  const [contentType, setContentType] = useState();
  const [userId, setUserId] = useState();
  const [contentTypeData, setContentTypeData] = useState();

  const inputRef = useRef(null);

  const image = [
    EyeGroup,
    Star,
    Star,
    Calender
  ];

  useEffect(() => {
    props.determinant();
    props.getTeacherList();
  }, [])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    borderRight: "1px solid #ffffff63",
    boxShadow: "none",
    borderRadius: "0px",
    color: "white"
  }));

  const select_determinant = (data) => {
    setSelectedDeteminants(data)
    props.determinantCategory(data?.Determinant)
    setActiveDeterminant(data?.ID);
  };

  const selectTeacherData = (data) => {
    setUserId(data?.jp_contentorguser?.UserID);
    setSelectedUserName(data?.jp_contentorguser?.jp_contentuserprofiles[0]?.DisplayName);
    setSelectedUserProfile(data?.jp_contentorguser?.jp_contentuserprofiles[0]?.Photo);
    setSelectedTeacher(data?.ID)
  }

  const selectDeterminatesCategory = (data, category) => {
    // props.determinantCategory(data?.Determinant)
    setCategory(category);
    props.determinantCategoryPractices(data?.Category)
    setActiveDeteminantCategory(data?.ID);
  };

  const selectDeterminatesCategoryPractices = (data, Practice) => {
    setPractices(Practice);
    setActiveDeteminantCategoryPractices(data?.ID)
  }

  const selectUploadContentTypeFunc = (data) => {
    setContentTypeData(data?.contentTitle);
    inputRef.current.click();
    setUploadContentType(data?.ID);
  }

  const continueWithSteps = async (data) => {
    if (currentStep.step === 3) {
      props.uploadAllKindOfFiles(contentType, 3);
    } else if (currentStep.step === 4) {
      props.uploadAllKindOfFiles(thumbnile, 4);
    }
    setCurrentStep(stepsLevels[data.step])
  };

  const backToPreviouState = (data) => {
    setCurrentStep(stepsLevels[data.index])
  }

  const handleFileChange = event => {
    setContentType(event.target.files[0]);
  };

  const navigateContatPage = () => {
    navigate("/content" + "/all");
  }

  const publishContent = () => {
    props.createContentByAdmin(userId, selectDeterminants?.Determinant, category, practices, contentTypeData, contentTitle, contenttype, refLinkOne, refLinkTwo, description, 0, 0, 0, thumnil, navigateContatPage);
  }

  const styles = {
    paperContainer: {
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      height: '370px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: 'url',
      backgroundImage: `url(${props?.uploadFilesRes?.data?.Location})`,
      borderRadius: '7px'
    }
  };

  const breadcrumbs = [
    <Link underline="none" key="1" color="#9fa4a5" href="" fontSize={"13px"} display={"block"} className="avenir-semi">
      {category}
    </Link>,
    <Typography key="2" color="#9fa4a5" fontSize={"13px"} className="avenir-semi" lineHeight={"0px"}>
      {
        practices
      }
    </Typography>
  ];

  return (
    <Hoc activeMenu="Create Content">
      <div>
        <div className="full_wrap out-box-new-content-wrap">
          <div className="full_wrap head-tile-create">
            <h4 className="step-title">STEP {currentStep.step}</h4>
            <h4 className="main-title">{currentStep.name}</h4>
          </div>
          <div className="full_wrap steps-out-wrap">
            <ul>
              <li className="container__item">
                <div className="stepper">
                  <input className="stepper__input" id="stepper-5-0"
                    name="stepper-5"
                    type="radio"
                    checked={currentStep.step === 1 ? "checked" : ""}
                  />
                  <div className="stepper__step">
                    <label className="stepper__button" for="stepper-5-0"></label>
                    <p className="stepper__content">
                      Choose <br /> Teacher
                    </p>
                  </div>
                  <input
                    className="stepper__input"
                    id="stepper-5-1"
                    name="stepper-5"
                    type="radio"
                    checked={currentStep.step === 2 ? "checked" : ""}
                  />
                  <div className="stepper__step">
                    <label className="stepper__button" for="stepper-5-0"></label>
                    <p className="stepper__content">
                      Choose <br /> Determinant
                    </p>
                  </div>
                  <input
                    className="stepper__input"
                    id="stepper-5-1"
                    name="stepper-5"
                    type="radio"
                    checked={currentStep.step === 3 ? "checked" : ""}
                  />
                  <div className="stepper__step">
                    <label className="stepper__button" for="stepper-5-1"></label>
                    <p className="stepper__content">Choose <br /> Media</p>
                  </div>
                  <input
                    className="stepper__input"
                    id="stepper-5-2"
                    name="stepper-5"
                    type="radio"
                    checked={currentStep.step === 4 ? "checked" : ""}
                  />
                  <div className="stepper__step">
                    <label className="stepper__button" for="stepper-5-2"></label>
                    <p className="stepper__content">Content <br /> Info</p>
                  </div>
                  <input
                    className="stepper__input"
                    id="stepper-5-3"
                    name="stepper-5"
                    type="radio"
                    checked={currentStep.step === 5 ? "checked" : ""}
                  />
                  <div className="stepper__step">
                    <label className="stepper__button" for="stepper-5-3"></label>
                    <p className="stepper__content">Content <br /> Preview</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {
            currentStep.step === 1 &&
            <div className="full_wrap list-content-out-wrap min-h-vh">
              <ul className="list-content-ul">
                {
                  props?.teachersList?.query?.count > 0 &&
                  props?.teachersList?.query?.rows?.map((data, id) => {
                    return (
                      <li onClick={() => selectTeacherData(data)} key={id} className="list-content-li">
                        <div className={selectedTeacher === data?.ID ? "inner-li-wrap check-btn-dot"
                          : "inner-li-wrap"
                        }
                        >
                          <img src={data?.jp_contentorguser?.jp_contentuserprofiles[0]?.Photo ? data?.jp_contentorguser?.jp_contentuserprofiles[0]?.Photo : ''} alt="like" />
                          <div>
                            <p className="pb-10">{data?.jp_contentorguser?.jp_contentuserprofiles[0]?.DisplayName}</p>
                            <div className="content-type-description">{data?.jp_contentorguser?.jp_contentuser?.EmailID}</div>
                          </div>
                          <div className="ab-tick-btn">
                            {/* <img className="selected-detereminant" src={BtnDot} alt="BtnDot" /> */}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          }
          {
            currentStep.step === 2 &&
            <div className="full_wrap list-content-out-wrap min-h-vh">
              {
                !selectDeterminants &&
                <ul className="list-content-ul">
                  {
                    props?.determinantData?.jpDeterminantsData?.map((data, id) => {
                      return (
                        <li onClick={() => select_determinant(data)} key={id} className="list-content-li">
                          <div className={activeDeterminant === data?.ID ? "inner-li-wrap check-btn-dot" : "inner-li-wrap"}>
                            <img src={data?.ICON ? require(`../../assets/determine${data?.ICON}`) : ''} alt="like" />
                            <p>{data?.Determinant}</p>
                            <div className="ab-tick-btn">
                              <img className="selected-detereminant" src={BtnDot} alt="BtnDot" />
                            </div>
                          </div>
                        </li>
                      );
                    })
                  }
                </ul>
              }
              {/* step 2 sub part code written here */}
              {
                selectDeterminants &&
                <div>
                  <div className="full_wrap list-content-out-wrap min-h-vh">
                    <ul className="list-content-ul">
                      <li className="list-content-li">
                        <div className={activeDeterminant === selectDeterminants?.ID ? "inner-li-wrap check-btn-dot"
                          : "inner-li-wrap"
                        }
                        >
                          <img src={selectDeterminants?.ICON ? require(`../../assets/determine${selectDeterminants?.ICON}`) : ''} alt="like" />
                          <p>{selectDeterminants?.Determinant}</p>
                          <div className="ab-tick-btn">
                            <img className="selected-detereminant" src={BtnDot} alt="BtnDot" />
                          </div>
                        </div>
                      </li>
                      <li className="list-content-li">
                        <div className="inner-li-wrap">
                          <input className="w-100" type={'button'} value={'Back To Determinants'} onClick={() => {
                            setSelectedDeteminants()
                          }} />
                        </div>
                      </li>
                    </ul>
                    <h4>SELECT CATEGORY</h4>
                    <ul className="list-content-ul">
                      {
                        props?.determinantCategories?.data?.map((data, id) => {
                          return (
                            <li key={id} className="list-content-li">
                              <div onClick={() => selectDeterminatesCategory(data, data?.Category)} className={activeDeteminantCategory === data?.ID ? "inner-li-wrap check-btn-dot" : "inner-li-wrap"}>
                                <img src={ListImg} alt="like" />
                                <p className="text-wrap-for-height">{data?.Category}</p>

                                <div className="ab-tick-btn">
                                  <img className="selected-detereminant" src={BtnDot} alt="BtnDot" />
                                </div>
                              </div>
                              <div className="ab-drop-search-section">
                                <div className="search-drop-wrap">
                                  <input
                                    className="w-100"
                                    placeholder="Search Category.."
                                    type={"text"}
                                  />
                                  <div className="ab-search-icon-inner-wrap">
                                    <img src={SearchIcon} alt="SearchIcon" />
                                  </div>
                                </div>
                                <div className="list-search-out-wrap">
                                  {
                                    props?.determinantCategoriesPractices?.data?.map((res, id) => {
                                      return (
                                        <label key={id} onClick={() => selectDeterminatesCategoryPractices(res, res?.Practice)} className={activeDeteminantCategoryPractices === res?.ID ? "select-search-list active-btn" : "select-search-list"}>{res?.Practice}</label>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>

                  </div>
                </div>
              }
              {/* end */}
            </div>
          }
          {
            currentStep.step === 3 &&
            <div className="full_wrap list-content-out-wrap min-h-vh">
              <ul className="list-content-ul">
                {contentUploadTypes.map((info, id) => {
                  return (
                    <li onClick={() => selectUploadContentTypeFunc(info)} key={id} className="list-content-li">
                      <div className={activeUploadContentType === info?.ID ? "inner-li-wrap min-h-uploadUI upload-active" : "inner-li-wrap min-h-uploadUI"} >
                        <div className="tick-curve-ab">
                          <img src={TickCurve} alt="TickCurve" />
                        </div>
                        <input
                          style={{ display: 'none' }}
                          ref={inputRef}
                          type="file"
                          onChange={handleFileChange}
                        />
                        <div className="image-border-ring">
                          <img src={require(`../../assets/images/upload-content/${info.contentImage}`)} alt="like" />
                        </div>
                        <div>
                          <p className="pb-10">{info.contentTitle}</p>
                          <div className="content-type-description">{info.description}</div>
                        </div>
                        <div className="ab-tick-btn">
                          <img className="selected-detereminant" src={BtnDot} alt="BtnDot" />
                        </div>
                      </div>
                    </li>
                  )
                })
                }
              </ul>
            </div>
          }
          {
            currentStep.step === 4 &&
            <div className="full_wrap list-content-out-wrap min-h-vh">
              <div className="list-wrap-drop">
                <div className="content-fields">
                  <div className="select-tag-header">CONTENT TITLE</div>
                  <input className="cus-in-text-wrap" onChange={(e) => setContentTitle(e.target.value)} type={'text'} />
                </div>
                <div className="content-fields">
                  <div className="select-tag-header">ADD DESCRIPTION</div>
                  <textarea className="cus-in-text-wrap" onChange={(e) => setDescription(e.target.value)} ></textarea>
                </div>
                <div>
                  <div className="content-fields">
                    <div className="select-tag-header">REFERENCE LINK 1</div>
                    <input className="cus-in-text-wrap" onChange={(e) => setRefLinkOne(e.target.value)} type={'text'} />
                  </div>
                  <div className="content-fields">
                    <div className="select-tag-header">REFERENCE LINK 2</div>
                    <input className="cus-in-text-wrap" onChange={(e) => setRefLinkTwo(e.target.value)} type={'text'} />
                  </div>
                  <div className="content-fields">
                    <div className="select-tag-header">REFERENCE LINK 3</div>
                    <input className="cus-in-text-wrap" onChange={(e) => setRefLinkThree(e.target.value)} type={'text'} />
                  </div>
                </div>

                <div className="content-fields">
                  <div className="select-tag-header">THUMBNAIL</div>
                  <input className="cus-in-text-wrap" onChange={(e) => setThumbnile(e.target.files[0])} type={'file'} />
                </div>
              </div>
            </div>
          }
          {
            currentStep.step === 5 &&
            <div className="full_wrap list-content-out-wrap min-h-vh">
              {/* <div className="list-content-ul">
                <div>Preview all Information</div>
              </div> */}
              <Card className="contents-detals-wrap br-grey rounded-3 mt-4 create-content-box" sx={{ display: "flex", padding: "20px 20px" }}>
                {/* <DialogView open={props?.modelToggleData?.toggle} onClose={handleClose} /> */}
                {/* <SideCommentComponents open={props?.modelToggleData?.toggle} onClose={handleClose} /> */}
                <Box sx={{ width: "30%", position: "relative" }}>
                  <div style={styles.paperContainer}>
                    {
                      props?.uploadFilesResLoading && <CircularProgress sx={{ position: 'relative', zIndex: '100' }} size={25} color="success" />
                    }
                  </div>
                  <div className="ab-card-play-btn">
                    <React.Fragment>
                      <ModalVideo
                        channel='custom'
                        url={""}
                        autoplay
                        allowFullScreen={true}
                        ratio='16:9'
                        isOpen={videoModelToggle}
                        onClose={() => setVideoModelToggle(false)}
                      />
                    </React.Fragment>
                    {
                      !props?.uploadFilesResLoading && <a
                        href="{modal}"
                        onClick={(event) => {
                          event.preventDefault();
                          setVideoModelToggle(true);
                        }}
                      >
                        <PlayArrowIcon /> Play
                      </a>
                    }
                  </div>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", padding: "0px 0px 0px 25px", width: "70%" }}>
                  <CardContent className="mb-3 pb-10" sx={{ flex: "0 0 auto", padding: "0px" }}>
                    <Breadcrumbs separator="•" aria-label="breadcrumb" style={{ color: "#9fa4a5", textTransform: "uppercase", fontSize: "30px", lineHeight: "0.6em" }}>
                      {breadcrumbs}
                    </Breadcrumbs>
                  </CardContent>
                  <h4 className="common-header fs-30 avenir-bold white pb-10">{contentTitle}</h4>
                  <p className="sub-title fs-17 pb-10">
                    Submitted on: 20th Sept, 2022
                  </p>
                  <Box sx={{ marginTop: "20px", marginBottom: "10px" }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >

                      {image.map((item, index) => {
                        return (
                          <Grid className="contentd-ul-list pb-10" item xs={2} sm={4} md={3} key={index}>
                            <Item className="contentd-list">
                              <div>
                                <img src={item} alt="React Logo" />
                              </div>
                              <div className="title-views">
                                {
                                  index === 0 && (
                                    <>
                                      <p>VIEWS</p> 0
                                    </>
                                  )
                                }
                                {
                                  index === 1 &&
                                  (
                                    <>
                                      <p>RATING</p> 0
                                    </>
                                  )
                                }
                                {
                                  index === 2 && (
                                    <>
                                      <p>DETERMINANT</p>{selectDeterminants?.Determinant ? selectDeterminants?.Determinant : 0}
                                    </>
                                  )
                                }
                                {
                                  index === 3 && (
                                    <>
                                      <p>PUBLISH DATE</p> 0
                                    </>
                                  )
                                }

                              </div>
                            </Item>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Box>
                  <hr />
                  <Box sx={{
                    display: "flex", width: "100%", justifyContent: "space-between",
                    alignItems: "center"
                  }}>

                    <List sx={{ maxWidth: 360 }}>

                      <ListItem sx={{ padding: '0px' }}>
                        <ListItemAvatar >
                          <Avatar alt="Travis Howard" src={selectedUserProfile} />
                        </ListItemAvatar>
                        <ListItemText className="secendary" sx={{ color: 'white' }} primary={capitalizeFirstLowercaseRest(selectedUserName)} />
                      </ListItem>


                    </List>
                    {/* <Button onClick={() => ViewProfile(props?.cuntentDetailUser?.data)} sx={{ color: "white", borderColor: "#4a5456" }} className="rounded-3" variant="outlined" size="large">
                              View Profile
                          </Button> */}
                  </Box>
                </Box>
              </Card>
              <Box className="rounded-3 br-grey mt-4 create-content-box" sx={{ flexGrow: 2 }}>
                <Grid sx={{ minHeight: '400px' }} container spacing={2}>
                  <Grid xs={12} md={8} className="description-left-side">
                    <div className="border-right h-100 inn-des-left-wrap d-flex flex-column justify-content-center">
                      <h6 className="head-title-cus">
                        Description
                      </h6>
                      <p style={{ color: "#fff" }}>
                        {
                          description
                        }
                      </p>
                      <h6 className="head-title-cus mt-4">
                        Reference Links
                      </h6>
                      <LinkTag data={refLinkOne} />
                      <LinkTag data={refLinkTwo} />
                      <LinkTag data={refLinkThree} />

                    </div>
                  </Grid>
                  <Grid xs={12} md={4} className="graph-right-side">
                  </Grid>
                </Grid>
              </Box>
            </div>
          }

          <div className="full_wrap bottom-btn-side-wrap">
            {
              currentStep.step != 1 &&
              <a className="step-btn-wrap" onClick={() => { backToPreviouState(currentStep) }}>
                <img src={LeftBack} alt="BtnDot" />
              </a>
            }
            {
              currentStep.step != 5 &&
              <a className="step-btn-wrap disable-btn" onClick={() => { continueWithSteps(currentStep) }}>
                Continue
              </a>
            }
            {
              currentStep.step === 5 &&
              <a className="step-btn-wrap disable-btn" onClick={() => { publishContent() }}>
                Publish Content
              </a>
            }
          </div>
        </div>
      </div>
    </Hoc>
  );
};

CreateContent.propTypes = {};
CreateContent.defaultProps = {};

const actionCreators = {
  determinant,
  determinantCategory,
  determinantCategoryPractices,
  getTeacherList,
  uploadAllKindOfFiles,
  createContentByAdmin
};

export default connect(
  ({ content }) => ({
    determinantData: content.determinant,
    determinantCategories: content.determinantCategories,
    determinantCategoriesPractices: content.determinantCategoriesPractices,
    teachersList: content.teachersList,
    uploadFilesRes: content.uploadFilesRes,
    uploadFilesResLoading: content.uploadFilesResLoading,
    createContentByAdminData: content.createContentByAdmin
  }),
  actionCreators
)(CreateContent);