import React, { forwardRef, useState } from "react";
import "./header.scss";
import "../../pages/Dashboard/dashboard.scss";
import { useParams } from "react-router-dom";
import { Box, Button, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import Search from "../../assets/images/search.png";
import Bell from "../../assets/images/notification-bing.png";
import Notification from '../../assets/images/notification.png'
// import Menu from "../../assets/images/sort.png";
import Menu from "../../assets/images/active_menu.png";
import ActiveMenu from '../../assets/images/sortActive.png'
import NotiImg from "../../assets/images/noti-1.png";
import ButtonInput from "../../atoms/button";
import {
  grideListToggle,
  modelToggle,
  drawerToggle,
} from "../../redux/action/user";
import { approveContent, reworkContent } from "../../redux/action/content";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Avatar from "@mui/material/Avatar";
import DownCircle from "../../assets/images/down-circle.png";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Header = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [menuToggle, setMenuToggle] = useState(false);
  const [change, setChange] = useState("Grid");
  const [notificationModel, setNotificationModel] = useState(false);
  const [activeModal, setActiveModal] = useState();
  const [activeMenu, setActiveMenu] = useState();
  const [sortToggle, setSortByToggle] = useState(false);
  const [sortByname, setSortByName] = useState("");
  const [notificationData, setNotificationData] = useState([
    {
      image: NotiImg,
      msg: "👍 Congratulations! Your content  “How long to hold  an asana?” get approved by admin.",
      time: "about 6 mins ago",
    },
    {
      image: NotiImg,
      msg: "👍 Congratulations! Your content  “How long to hold  an asana?” get approved by admin.",
      time: "about 6 mins ago",
    },
    {
      image: NotiImg,
      msg: "👍 Congratulations! Your content  “How long to hold  an asana?” get approved by admin.",
      time: "about 6 mins ago",
    },
    {
      image: NotiImg,
      msg: "👍 Congratulations! Your content  “How long to hold  an asana?” get approved by admin.",
      time: "about 6 mins ago",
    },
  ]);

  const { id, activeTabName } = useParams();

  const notification = (toggle, modelName) => {
    setActiveModal(modelName);
    setNotificationModel(toggle);
    if (toggle === "clear") {
      setNotificationData([]);
      setTimeout(() => {
        setNotificationModel(false);
      }, [300]);
    }
  };
  const menuNavigate = (data) => {
    setActiveMenu(data);
    if (data === "myProfile") {
      navigate("/Setting");
      setNotificationModel(false);
    } else if (data === "logout") {
      navigate("/");
      setNotificationModel(false);
      localStorage.setItem("authToken", null);
      localStorage.setItem("isLogin", false);
      localStorage.setItem("loginUser", null);
    }
  };

  const GriteToggleChange = (data) => {
    setChange(data);
    if (data === "Grid") {
      props.grideListToggle(false);
    } else if (data === "List") {
      props.grideListToggle(true);
    }
  };

  const changePassword = () => {
    props.modelToggle(true, "change_password");
  };

  const RemoveModel = () => {
    // console.log("props?.contentDetailList?.data?.content?.ContentCurrentStatus ", props?.contentDetailList?.data?.content?.ContentCurrentStatus)
    if (
      props?.contentDetailList?.data?.content?.ContentCurrentStatus ===
      "Approved"
    ) {
      props.modelToggle(true, "remove_model");
    } else if (
      props?.contentDetailList?.data?.content?.ContentCurrentStatus ===
      "Disapproved" ||
      "Under Review"
    ) {
      props.modelToggle(true, "dis_approve");
    } else {
      console.log(" no event fire");
    }
  };

  const ApproveContent = (id) => {
    if (
      props?.contentDetailList?.data?.content?.ContentCurrentStatus ===
      "Under Review"
    ) {
      props.approveContent(id);
    }
    if (
      props?.contentDetailList?.data?.content?.ContentCurrentStatus ===
      "Approved" ||
      "Disapproved"
    ) {
      props.reworkContent(id);
    }
    // navigate("/content");
  };

  const sortBy = (imgToggle, modalToggle) => {
    setMenuToggle(imgToggle);
    setSortByToggle(modalToggle);
  };


  return (
    <>
      <div className="topbar-container">
        <div className="gap-between">
          {/* <img className="search-bar-icon" src={Search} alt="search " />
          <span className="op-5">|</span>
          <input
            type="text"
            placeholder="Search here..."
            className="input-search"
          /> */}
        </div>
        <div className="right-item">
          {/* <div className="bell-icon p-5">
            <img
              src={Notification}
              onClick={() =>
                notification(!notificationModel, "notificationModel")
              }
              alt="bell"
            />
            {notificationModel && activeModal === "notificationModel" && (
              <div className="ab-dp-noti">
                <ul className="drop-ul-wrap">
                  {notificationData?.map((data, index) => {
                    return (
                      <li key={index}>
                        <div className="noti-desc-wrap">
                          <img src={data?.image} alt="NotiImg" />
                          <div className="inner-span-tag-wrap">
                            <p className="msg-text">{data?.msg}</p>
                            <span className="msg-text">{data?.time}</span>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div
                  className="clear-bottom-wrap"
                  onClick={() => notification("clear", "notificationModel")}
                >
                  <h3>Clear</h3>
                </div>
              </div>
            )}
          </div>
          <span className="op-5">|</span> */}
          <div className="bell-icon">
            <div
              onClick={() => notification(!notificationModel, "profileModel")}
            >
              <div className="ab-down-wrap-img">
                <img src={DownCircle} alt="DownCircle" />
              </div>
              <Avatar
                alt="Travis Howard"
                src={
                  "https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?w=2000"
                }
              />
            </div>
            {notificationModel && activeModal === "profileModel" && (
              <div className="ab-dp-profile">
                <div className="heaer-profile-tag-title">
                  <h5>menu</h5>
                </div>
                <ul className="profile-ul-wrap">
                  <li onClick={() => menuNavigate("myProfile")}>
                    <p className={activeMenu === "menu" && "active-p"}>
                      My Profile
                    </p>
                  </li>
                  <li onClick={() => menuNavigate("logout")}>
                    <p className={activeMenu === "logout" && "active-p"}>
                      Logout
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="menu-item-container">
        {props?.activeMenu !== "Dashboard" && (
          <div style={{ display: "flex", alignItems: "flex-end", gap: '10px' }}>
            {props.role == 1 &&
              ref.current !== null &&
              ref.current !== undefined && (
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ marginTop: "10px", marginRight: "10px" }}
                >
                  <IconButton
                    onClick={() => ref.current.handleBackButton()}
                    color="inherit"
                    sx={{
                      backgroundColor: "#e29c2e",
                      borderRadius: "50%",
                      padding: 1,
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                    }}
                  >
                    <ArrowBackIcon sx={{ color: "white" }} />
                  </IconButton>
                  {/* <Typography
                    variant="body1"
                    color="white"
                    sx={{ marginLeft: 1, textDecoration: "underline" }}
                  >
                    Back
                  </Typography> */}
                </Box>
              )}
            <h5 className="common-title avenir-bold">
              {props?.subTitle ===
                "DETERMINATS > " +
                props.getParticularDeterminantRes?.determinant?.Determinant.toUpperCase()
                ? props.getParticularDeterminantRes?.determinant?.Determinant
                : props?.activeMenu}
            </h5>
            {activeTabName !== undefined && (
              <Breadcrumbs
                separator={
                  <NavigateNextIcon sx={{ color: "#fff" }} fontSize="small" />
                }
                aria-label="breadcrumb"
                style={{
                  color: "#9fa4a5",
                  textTransform: "uppercase",
                  fontSize: "30px",
                  lineHeight: "0.6em",
                }}
              >
                <Typography
                  underline="none"
                  key="1"
                  color="#9fa4a5"
                  href=""
                  fontSize={"13px"}
                  display={"block"}
                  className="avenir-semi"
                >
                  {props?.subTitle}
                </Typography>
                ,
                <Typography
                  key="2"
                  color="#fff"
                  fontSize={"13px"}
                  className="avenir-semi"
                  lineHeight={"0px"}
                >
                  {activeTabName === "all"
                    ? "ALL CONTENT"
                    : activeTabName?.toUpperCase()}
                </Typography>
              </Breadcrumbs>
            )}
            {activeTabName == undefined && (
              <p className="op-5 mb-2 h6 text-dark">{props?.subTitle}</p>
            )}
          </div>
        )}
        {props?.activeMenu == "Settings" && (
          <div className="right-menu-item">
            <div onClick={changePassword}>
              <p className="chanePasswordLabel">CHANGE PASSWORD</p>
            </div>
          </div>
        )}

        {props?.activeMenu === "User Management" &&
          props?.shoeDetailToggle === false && (
            <div className="right-menu-item">
              {props.role === 3 && (
                <>
                  <div className="menu-icon-wrap">
                    <img
                      onClick={() => sortBy(!menuToggle, !sortToggle)}
                      className="menu-das-icon"
                      src={menuToggle ? ActiveMenu : Menu}
                      alt="menu"
                    />
                    <div className="drop-down-cus-wrap"></div>
                  </div>
                  {sortToggle && (
                    <div className="sort-by-modal-container">
                      <div className="heaer-profile-tag-title">
                        <h5 className="modal-title">SORT BY</h5>
                      </div>
                      <ul className="profile-ul-wrap">
                        <li className="list-item">
                          <p
                            className={
                              sortByname === "Most Profile"
                                ? "lable-fonts active-p"
                                : "lable-fonts"
                            }
                            onClick={() => setSortByName("Most Profile")}
                          >
                            Most Profile
                          </p>
                          {sortByname === "Most Profile" && (
                            <CheckRoundedIcon size={25} color="success" />
                          )}
                        </li>
                        <li className="list-item">
                          <p
                            className={
                              sortByname === "Least Active"
                                ? "lable-fonts active-p"
                                : "lable-fonts"
                            }
                            onClick={() => setSortByName("Least Active")}
                          >
                            Least Active
                          </p>
                          {sortByname === "Least Active" && (
                            <CheckRoundedIcon size={25} color="success" />
                          )}
                        </li>
                        <li className="list-item">
                          <p
                            className={
                              sortByname === "Highest Rating"
                                ? "lable-fonts active-p"
                                : "lable-fonts"
                            }
                            onClick={() => setSortByName("Highest Rating")}
                          >
                            Highest Rating
                          </p>
                          {sortByname === "Highest Rating" && (
                            <CheckRoundedIcon size={25} color="success" />
                          )}
                        </li>
                        <li className="list-item">
                          <p
                            className={
                              sortByname === "Lowest Rating"
                                ? "lable-fonts active-p"
                                : "lable-fonts"
                            }
                            onClick={() => setSortByName("Lowest Rating")}
                          >
                            Lowest Rating
                          </p>
                          {sortByname === "Lowest Rating" && (
                            <CheckRoundedIcon size={25} color="success" />
                          )}
                        </li>
                        <li className="list-item">
                          <p
                            className={
                              sortByname === "Most Views"
                                ? "lable-fonts active-p"
                                : "lable-fonts"
                            }
                            onClick={() => setSortByName("Most Views")}
                          >
                            Most Views
                          </p>
                          {sortByname === "Most Views" && (
                            <CheckRoundedIcon size={25} color="success" />
                          )}
                        </li>
                        <li
                          className="list-item"
                          onClick={() => setSortByName("Least Views")}
                        >
                          <p
                            className={
                              sortByname === "Least Views"
                                ? "lable-fonts active-p"
                                : "lable-fonts"
                            }
                          >
                            Least Views
                          </p>
                          {sortByname === "Least Views" && (
                            <CheckRoundedIcon size={25} color="success" />
                          )}
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              )}
              <div onClick={props?.onClick}>
                <ButtonInput class="clsBtn" text="+ Create User" />
              </div>
            </div>
          )}
        {props?.activeMenu === "Program & Subscriptions" &&
          props?.shoeDetailToggle === false && (
            <div className="right-menu-item">
              {/* <div className="menu-icon-wrap">
                <img
                  onClick={() => sortBy(!menuToggle, !sortToggle)}
                  className="menu-das-icon"
                  src={menuToggle ? ActiveMenu : Menu}
                  alt="menu"
                />
                <div className="drop-down-cus-wrap"></div>
              </div>
              {sortToggle && (
                <div className="sort-by-modal-container">
                  <div className="heaer-profile-tag-title">
                    <h5 className="modal-title">SORT BY</h5>
                  </div>
                  <ul className="profile-ul-wrap">
                    <li className="list-item">
                      <p
                        className={
                          sortByname === "AtoZ"
                            ? "lable-fonts active-p"
                            : "lable-fonts"
                        }
                        onClick={() => setSortByName("AtoZ")}
                      >
                        Sort Alphabetically
                      </p>
                      {sortByname === "AtoZ" && (
                        <CheckRoundedIcon size={25} color="success" />
                      )}
                    </li>
                  </ul>
                </div>
              )} */}
              <div onClick={() => navigate("/programs/new")}>
                <ButtonInput class="clsBtn" text="+ Add New" />
              </div>
            </div>
          )}
        {props?.activeMenu === "Contents" && (
          <div className="right-items">
            <button
              onClick={() => GriteToggleChange("List")}
              className={change === "List" ? "buttons buttons-2" : "buttons"}
            >
              Grid
            </button>
            <button
              onClick={() => GriteToggleChange("Grid")}
              className={change === "Grid" ? "buttons buttons-2" : "buttons"}
            >
              List
            </button>
          </div>
        )}
        {props?.activeMenu === "Contents details" && (
          <div className="btnOuterLayer">
            <img
              className="sideMsgContainer"
              onClick={() => props?.drawerToggle({ right: true })}
              src={require("../../assets/images/msg.png")}
              alt=""
            />
            <Button
              onClick={() => RemoveModel()}
              variant="outlined"
              className="btnOutline cus-btn-62"
              sx={{
                backgroundColor: "#02090a",
                borderColor: "#434d4f",
                color: "#ffffff",
                marginRight: "10px",
              }}
            >
              {props?.contentDetailList?.data?.content?.ContentCurrentStatus ===
                "Under Review"
                ? "DISAPPROVE"
                : "REMOVE"}
            </Button>
            <Button
              onClick={() =>
                ApproveContent(props?.contentDetailList?.data?.content?.ID)
              }
              className="btn"
              variant="contained"
            >
              {props?.contentDetailList?.data?.content?.ContentCurrentStatus ===
                "Under Review"
                ? "APPROVE"
                : "REQUEST UPDATE"}{" "}
            </Button>
          </div>
        )}

        {props?.shoeDetailToggle && (
          <div className="user-details-pop-up-side text-dark">
            <span>Created by: Melissa</span>
            <p>on 20th Dec, 2021</p>
          </div>
        )}
      </div>
      <div className="menu-item-container"></div>
    </>
  );
});

Header.propTypes = {};
Header.defaultProps = {};

const actionCreators = {
  grideListToggle,
  modelToggle,
  drawerToggle,
  approveContent,
  reworkContent,
};

export default connect(
  ({ user, content }) => ({
    shoeDetailToggle: user.shoeDetailToggle,
    listGrideToggle: user.listGrideToggle,
    modelToggleData: user.modelToggle,
    contentActiveTabeName: user.contentActiveTabeName,
    drawerToggleData: user.drawerToggleData,
    contentAppruved: content.contentAppruved,
    contentDetailList: content.contentDetailList,
    getParticularDeterminantRes: content.getParticularDeterminantRes,
  }),
  actionCreators,
  null,
  { forwardRef: true }
)(Header);
