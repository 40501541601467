// AnalysisTab.js
import React from 'react';

const AnalysisTab = () => {
  return (
    <div>
      <img src={require('../../assets/images/qr1.png')} style={{ resize: 'cover', width: '100px', height: '100px' }} />
    </div>
  );
};

export default AnalysisTab;
