import React, { useState, useEffect } from "react";
import "./Sidebar.scss";
import sidebarImage from "../../assets/images/logo2.png";
import Menu from "../../assets/images/icons8-menu-24.png";
import { useNavigate } from "react-router";
import { Navigation } from "../../constants/NavigationsMenu";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { connect } from "react-redux";
import { modelToggle, showDetail, grideListToggle } from "../../redux/action/user";
import ActiveHome from '../../assets/images/homeActive.png';
import ActiveGride from '../../assets/images/activeGrid.png';
import ActiveUser from '../../assets/images/ActiveProfile.png';
import ActiveDeterminance from '../../assets/images/ActiveFolder.png';
import ActiveSetting from '../../assets/images/ActiveSetting.png';
import ActiveHelp from '../../assets/images/ActiveHelp.png';
import ActiveLogout from '../../assets/images/ActiveLogOut.png';
import { capitalizeFirstLowercaseRest } from "../../constants/constant";

function Sidebar(props) {
  const navigate = useNavigate();
  const [handleMobileMenuState, setHandleMobileMenuState] = useState(false);

  const MoveNextScreen = (screenName, activeName) => {
    if (activeName === "User Management") {
      props.showDetail(false);
    }
    if (screenName === "/logout") {
      navigate('/');
      localStorage.setItem('authToken', null);
      localStorage.setItem('isLogin', false);
      localStorage.setItem('loginUser', null);
    }
    else {
      if (screenName === '/content') {
        navigate(screenName + "/all");
      } else {
        navigate(screenName);
      }
    }
  }

  useEffect(() => {
    props.modelToggle(false, "");
    props.showDetail(false);
    props.grideListToggle(false);
  }, [props.selected])

  return (
    <>
      <div
        className={`mobile-panel-icon`}
        onClick={() => setHandleMobileMenuState(!handleMobileMenuState)}
      >
        <img src={Menu} alt="icon" />
      </div>
      <div
        className={`col-2 sidebar-container ${handleMobileMenuState === true && "show"
          } `}
      >
        <div className="sidebar-wrapper pt-2">
          <div onClick={() => navigate('/dashboard')} className="side-image-container">
            <img
              src={sidebarImage}
              alt="leftimage"
              className="left-inner-image"
            />
            <span>Hey, Welcome Back!</span>
            <h1>{capitalizeFirstLowercaseRest(props?.currentUser?.data?.contentUser?.Fullname ? props?.currentUser?.data?.contentUser?.Fullname : '...')}</h1>
            <div>
              <img src={require('../../assets/images/Spliter.png')} />
            </div>
          </div>
          <div className="side-overview-menu">
            <h1 className="overview-text">Overview</h1>
            <ul className="overview-menu">
              {Navigation.overviewMenu.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => MoveNextScreen(item.link, item.name)}
                    className={
                      item.name === "Contents" && props?.selected === "Contents details" ? "overview-menu-item" :
                        props?.selected === item.name
                          ? "overview-menu-item"
                          : "overview-menu-item2"
                    }
                  >
                    <div className="iconName">
                      <img src={
                        props?.selected === item.name
                          ? (item.name === "Contents" && ActiveGride)
                          || (item.name === "Dashboard" && ActiveHome)
                          || (item.name === "User Management" && ActiveUser)
                          || (item.name === "Determinants" && ActiveDeterminance)
                          || (item.name === "Program & Subscriptions" && ActiveDeterminance)
                          : item.image
                      } alt={item.id} />

                      <span style={{ marginLeft: '15px' }}>
                        {item.name}
                      </span>
                    </div>
                    <span>{item.name === "Contents" && props?.selected === "Contents details" ? <ArrowForwardIosIcon fontSize="20" style={{ color: '#0BD7EE' }} /> : props?.selected === item.name && <ArrowForwardIosIcon fontSize="20" style={{ color: '#0BD7EE' }} />}</span>
                  </li>)
              })}
            </ul>
          </div>
          <div className="side-general-menu">
            <div>
              <img src={require('../../assets/images/Spliter.png')} />
            </div>
            <h1 className="general-text">Genaral</h1>
            <ul className="general-menu">
              {Navigation.general.map((item, index) => (
                <li
                  key={index}
                  onClick={() => MoveNextScreen(item.link, item.name)}
                  className={
                    props?.selected === item.name
                      ? "overview-menu-item"
                      : "overview-menu-item2"
                  }
                >
                  <div className="iconName">
                    <img src={props?.selected === item.name ? item.name === "Help" && ActiveHelp || item.name === "Create Content" && ActiveGride || item.name === "Settings" && ActiveSetting || item.name === "LogOut" && ActiveLogout : item.image} alt={item.id} />
                    <span style={{ marginLeft: '15px' }}>
                      {item.name}
                    </span>
                  </div>
                  <span>{props?.selected === item.name && <ArrowForwardIosIcon fontSize="24" style={{ color: '#0BD7EE' }} />} </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

Sidebar.propTypes = {};
Sidebar.defaultProps = {};

const actionCreators = {
  modelToggle,
  showDetail, grideListToggle
};

export default connect(
  ({ user }) => ({
    modelToggleData: user.modelToggle,
    currentUser: user.currentUser
  }),
  actionCreators
)(Sidebar);
