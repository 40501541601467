import React, { useCallback, useEffect, useState } from "react";
import Hoc from "../../component/HOC";
import { connect } from "react-redux";
import {
  createProgram,
  getProgramDetails,
  showDetail,
  updateProgram,
  uploadFile,
} from "../../redux/action/user";
import {
  Button,
  Grid,
  Tab,
  Tabs,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import "./Program.scss";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import RichTextEditor from "./TextEditor";
import FormInput from "./FormInput";
import { useNavigate, useParams } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import { capitalizeFirstLetter, decodeHtmlEntities } from "../../common";

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);

const AddProgram = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    amount: null,
    currency: "usd",
    duration: 0,
    unit: "day",
    description: "",
    programType: "program",
    image: null,
  });

  // Rich Text Editors state
  const [editorContents, setEditorContents] = useState([""]);
  const [activeTab, setActiveTab] = useState(0);
  const [errors, setErrors] = useState({
    name: false,
    amount: false,
    currency: false,
    description: false,
    duration: false,
    unit: false,
    image: false,
  });
  const [helperText, setHelperText] = useState("");
  const [fullHtmlContent, setFullHtmlContent] = useState(
    generateHtml(formData, editorContents)
  );
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    props.showDetail(true);
    if (id != "new" && id) {
      props.getProgramDetails(id);
    }
    if (id == "new") {
      setImagePreview(null);
    }
    return () => props.showDetail(false);
  }, []);

  useEffect(() => {
    if (props.programDetails && props.programDetails?.ID) {
      const data = props.programDetails;
      setFormData({
        name: data.name || "",
        amount: data.amount || null,
        currency: data.currency || "usd",
        duration: data.duration || 0,
        unit: data.interval || "day",
        description: data.description || "",
        programType: data.isSubscription ? "subscription" : "program",
        image: { filePath: data.imageUrl },
      });
      setImagePreview(data.imageUrl);
      setFullHtmlContent(decodeHtmlEntities(data.summary));

      if (data.includes) {
        const decodedArray = decodeHtmlEntity(data.includes);
        setEditorContents(decodedArray || [""]); // Ensure there's a fallback if no contents
      } else {
        setEditorContents([""]); // If no includes field, set to an empty array
      }
    }
  }, [props.programDetails, id]);

  const decodeHtmlEntity = (encodedArray) => {
    if (encodedArray) {
      return (
        encodedArray &&
        encodedArray?.map((encodedString) => {
          const parser = new DOMParser();
          const decodedString = parser.parseFromString(
            encodedString,
            "text/html"
          ).body.textContent;
          return decodedString;
        })
      );
    } else return [];
  };

  useEffect(() => {
    if (id == "new") {
      // Reset form fields when navigating to the "New Program" page
      setFormData({
        name: "",
        amount: null,
        currency: "usd",
        duration: 0,
        unit: "day",
        description: "",
        programType: "program",
        image: null,
      });
      setEditorContents([""]); // Reset the editor content
    }
  }, [id]);

  // Handle file change (image upload)
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData1 = new FormData();
      formData1.append("file", file);
      const data = await props.uploadFile(formData1);
      if (data && data?.status === 200) {
        // setFormData({ ...formData, image: data.data });
        setFormData((prevData) => {
          const newData = { ...prevData, image: data.data };

          // After updating the form data, generate updated HTML content
          const updatedHtml = generateHtml(newData, editorContents);
          setFullHtmlContent(updatedHtml); // Update the full HTML content
          return newData;
        });

        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result); // Set the image preview URL
        };
        reader.readAsDataURL(file);
      }
      setErrors((prevErrors) => ({ ...prevErrors, ["image"]: false }));
    }
  };

  // Handle change in form fields
  const handleFormChange = useCallback(
    (field) => (e) => {
      setFormData((prevData) => {
        const newData = { ...prevData, [field]: e.target.value };

        const updatedHtml = generateHtml(newData, editorContents);
        setFullHtmlContent(updatedHtml);

        return newData;
      });

      setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
    },
    [editorContents]
  );

  // Handle delete for editor content
  const handleDelete = (index) => {
    const updatedContents = [...editorContents];
    updatedContents.splice(index, 1);
    setEditorContents(updatedContents);
    const updatedHtml = generateHtml(formData, updatedContents);
    setFullHtmlContent(updatedHtml);
  };

  // Handle change in text editor
  const handleEditorChange = useCallback(
    (index, value) => {
      setEditorContents((prevContents) => {
        const newContents = [...prevContents];
        newContents[index] = value;

        const updatedHtml = generateHtml(formData, newContents);
        setFullHtmlContent(updatedHtml);

        return newContents;
      });
    },
    [formData]
  );

  // Add a new editor
  const addEditor = () => {
    setEditorContents((prevContents) => [...prevContents, ""]);
  };

  // Handle the change in tabs
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const validateForm = () => {
    const newErrors = {};
    const fields = [
      "name",
      "amount",
      "currency",
      "duration",
      "unit",
      "description",
      "image",
    ];
    fields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = true;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    console.log('formData', validateForm(), errors)
    if (validateForm()) {
      const formData1 = new URLSearchParams();
      // Append basic fields (use empty string or placeholder values for optional fields if needed)
      formData1.append("name", formData.name || "");
      formData1.append("description", formData.description || "");
      formData1.append("amount", formData.amount || "");
      formData1.append("interval", formData.unit ? formData.unit : "day");
      formData1.append("currency", formData.currency || "");
      formData1.append("duration", formData.duration || 0);

      // Append the HTML content or any structured data
      formData1.append("summary", fullHtmlContent || "");

      if (editorContents && editorContents.length) {
        // Join the contents into a single string using a separator
        const contentString = editorContents.join("|"); // You can replace '||' with any other separator
        formData1.append("includes", contentString);
      } else {
        formData1.append("includes", "");
      }

      if (formData.image && formData.image.filePath) {
        formData1.append("imageUrl", formData.image.filePath);
      }

      // Use appropriate method to make the request
      if (id == "new") {
        formData1.append("status", "1");
        formData1.append(
          "isSubscription",
          formData.programType === "program" ? false : true
        );
        await props.createProgram(formData1);
      } else {
        formData1.append("ID", id);
        await props.updateProgram(formData1);
      }

      navigate("/programs");
    } else {
      setHelperText("Please fill out all required fields.");
    }
  };

  // Cancel action
  const handleCancel = () => {
    setFormData({
      name: "",
      amount: "",
      currency: "",
      description: "",
      duration: 0,
      unit: "day",
      programType: "program",
      image: null,
    });
    setEditorContents([""]);
    navigate(-1);
  };

  return (
    <Box sx={{ ...AddProgramWrapper }}>
      <Hoc subTitle={"Program & Subscriptions"}>
        <Box className="container-fluid p-0 mt-4" sx={{ ...PrgmSubs, mb: 3 }}>
          <Box sx={{ width: "100%", px: 3, ...EditorWrapper }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="basic tabs example"
            >
              <Tab label="Editor" />
              <Tab label="Preview" />
            </Tabs>
            <div style={{ display: activeTab === 0 ? "block" : "none" }}>
              <Grid container spacing={2} className="FieldsWrap">
                <Grid item xs={12} md={12}>
                  <FormInput
                    label="Name"
                    value={formData.name}
                    onChange={handleFormChange("name")}
                    placeholder="Enter Program Name"
                    error={errors.name}
                    helperText={errors.name ? "This field is required" : ""}
                  />
                  <FormInput
                    label="Currency"
                    value={
                      formData.currency && formData.currency?.toUpperCase()
                    }
                    onChange={handleFormChange("currency")}
                    placeholder="Enter Currency"
                    error={errors.currency}
                    helperText={errors.currency ? "This field is required" : ""}
                    disabled
                  />
                  <FormInput
                    label="Amount"
                    type="number"
                    value={formData.amount}
                    onChange={handleFormChange("amount")}
                    placeholder="Enter Amount"
                    error={errors.amount}
                    helperText={errors.amount ? "This field is required" : ""}
                  />
                  <FormInput
                    label="Description"
                    value={formData.description}
                    onChange={handleFormChange("description")}
                    placeholder="Enter Short Description"
                    error={errors.description}
                    helperText={
                      errors.description ? "This field is required" : ""
                    }
                  />
                  {/* <TextField
                      label="Description"
                      value={formData.description}
                      onChange={handleFormChange("description")}
                      placeholder={"Enter Short Description"}
                      error={errors.description}
                      helperText={
                        errors.description ? "This field is required" : ""
                      }
                      multiline
                      minRows={1}
                      maxRows={6}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        style: {
                          resize: "none",
                          wordWrap: "break-word",
                        },
                      }}
                      sx={{
                        padding: "7px 15px",
                        fontSize: "14px",
                        backgroundColor: "#1d2b2c",
                        border: "unset",
                        "& .MuiOutlinedInput-root": {
                          minHeight: "40px",
                        },
                      }}
                      className="form-text"
                    /> */}
                  {/* Image Upload Field */}
                  <Grid item xs={12} md={12}>
                    <Box
                      sx={{
                        backgroundColor: "transparent",
                        padding: "8px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <InputLabel
                        style={{
                          color: "#fff",
                          width: "12%",
                        }}
                      >
                        Program Image:
                      </InputLabel>
                      {/* <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{
                          color: "#000",
                          marginBottom: "8px",
                          backgroundColor: "#fff",
                          borderRadius: "5px",
                          border: "1px solid #80808047"
                        }}
                        error={errors.image}
                        helperText={
                          errors.image ? "This field is required" : ""
                        }
                      /> */}
                      <div>
                        <Button
                          variant="outlined"
                          component="label"
                          sx={{
                            color: "#000",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            border: "1px solid #80808047",
                            textTransform: "none",
                            marginBottom: "8px"
                          }}
                        >
                          Upload Image
                          <input type="file" accept="image/*" hidden onChange={handleImageChange} />
                        </Button>

                        {errors.image && (
                          <FormHelperText error>{errors.image ? "This field is required" : ""}</FormHelperText>
                        )}
                      </div>

                      {imagePreview && (
                        // <Box sx={{ marginTop: "10px" }}>
                        <img
                          src={imagePreview}
                          alt="Program Preview"
                          style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                            objectFit: "cover",
                            marginLeft: "10px",
                          }}
                        />
                        // </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box
                      sx={{
                        backgroundColor: "transparent",
                        padding: "8px",
                        display: "flex",
                        alignItems: "center",
                        "input[type=radio]": {
                          borderColor: "red",
                          "&:checked": {
                            "&:disabled": {
                              "&:before": {
                                content: '""',
                                top: "1px",
                                right: 0,
                                left: "1px",
                                display: "block",
                                position: "relative",
                                backgroundColor: "#007aff",
                                borderRadius: "50%",
                                height: "11px",
                                width: "11px",
                                opacity: "0.7",
                              },
                            },
                          },
                        },
                      }}
                    >
                      <InputLabel
                        style={{
                          color: "#fff",
                          width: "12%",
                        }}
                      >
                        Type:
                      </InputLabel>
                      <RadioGroup
                        row
                        value={formData.programType}
                        onChange={handleFormChange("programType")}
                        sx={{
                          padding: "7px 15px",
                          fontSize: "14px",
                          backgroundColor: "#fff",
                          border: "unset",
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="radio"
                            id="program"
                            name="programType"
                            value="program"
                            checked={formData.programType === "program"}
                            onChange={handleFormChange("programType")}
                            disabled={id != "new"}
                          />
                          <label htmlFor="program" style={{ marginLeft: 8, color: "#000" }}>
                            Program
                          </label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="radio"
                            id="subscription"
                            name="programType"
                            value="subscription"
                            checked={formData.programType === "subscription"}
                            onChange={handleFormChange("programType")}
                            style={{ marginLeft: 8 }}
                            disabled={id != "new"}
                          />
                          <label
                            htmlFor="subscription"
                            style={{ marginLeft: 8, color: "#000" }}
                          >
                            Subscription
                          </label>
                        </div>
                      </RadioGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box
                      sx={{
                        backgroundColor: "transparent",
                        padding: "8px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <label
                        style={{
                          marginRight: "8px",
                          color: "#fff",
                          width: "13%",
                        }}
                      >
                        Validity:
                      </label>{" "}
                      <Grid item xs={12} md={6} sx={{ marginRight: "10px" }}>
                        <TextField
                          type="number"
                          fullWidth
                          value={formData.duration}
                          onChange={(e) => handleFormChange("duration")(e)}
                          disabled={false}
                          label={"Durations"}
                          placeholder="Enter Durations"
                          error={errors.duration}
                          helperText={
                            errors.duration ? "This field is required" : ""
                          }
                          className="form-text"
                          sx={{
                            padding: "7px 15px",
                            fontSize: "14px",
                            backgroundColor: "#1d2b2c",
                            border: "unset",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {/* <InputLabel>Unit</InputLabel> */}
                        <Select
                          value={formData.unit}
                          onChange={handleFormChange("unit")}
                          // label="Unit"
                          className="form-text"
                          placeholder="Enter unit"
                          error={errors.unit}
                          sx={{
                            // padding: "7px 15px",
                            fontSize: "14px",
                            backgroundColor: "#fff",
                            // color: '#000 !importtant',
                            border: "unset",
                            width: "100%",
                            borderRadius: "5px",
                            // marginTop: "unset !important",
                            ".MuiSelect-select": {
                              padding: "7px 15px",
                            },
                          }}
                        // style={{ color: "#fff" }}
                        >
                          <MenuItem value="day">Days</MenuItem>
                          <MenuItem value="month">Months</MenuItem>
                          {formData.programType !== "subscription" && (
                            <MenuItem value="Lifetime">Lifetime</MenuItem>
                          )}
                        </Select>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box
                      sx={{
                        backgroundColor: "transparent",
                        padding: "8px",
                        display: "flex",
                        alignItems: "baseline",
                      }}
                      style={{ border: "unset" }}
                    >
                      <label
                        style={{
                          color: "#fff",
                          width: "12%",
                        }}
                      >
                        Includes:
                      </label>
                      <Box
                        sx={{
                          width: "88%",
                        }}
                        style={{ border: "unset", backgroundColor: 'unset' }}
                      >
                        {(id == "new" ||
                          (editorContents &&
                            editorContents.length &&
                            editorContents[0].length > 0)) &&
                          editorContents?.map((content, index) => (
                            <Box
                              key={index}
                              sx={{
                                position: "relative",
                                mb: 2,
                                backgroundColor: "#fff",
                              }}
                            >
                              {index !== 0 && (
                                <IconButton
                                  onClick={() => handleDelete(index)}
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    zIndex: 1,
                                    color: "#000",
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                              <RichTextEditor
                                key={index}
                                index={index}
                                value={content}
                                onChange={handleEditorChange}
                              />
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                sx={{
                  mb: 1,
                  mr: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  button: {
                    backgroundColor: "#0bd7ee",
                    color: "#000",
                    borderColor: "#0bd7ee",
                    textTransform: "unset !important",
                    fontSize: "0.8rem",
                    "&:hover, &,focus": {
                      outline: "none",
                      boxShadow: "none",
                      backgroundColor: "#0bd7ee",
                      borderColor: "#0bd7ee",
                    },
                  },
                }}
              >
                <Button variant="outlined" onClick={addEditor} sx={{ mt: 1 }}>
                  Add Section
                </Button>
              </Box>
              <Box
                sx={{
                  px: 2,
                  pb: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    mt: 1,
                    button: {
                      "&:nth-of-type(1)": {
                        backgroundColor: "#fff",
                        color: "#000",
                        borderColor: "#fff",
                        textTransform: "unset !important",
                        fontSize: "0.8rem",
                        mr: 2,
                        "&:hover, &,focus": {
                          outline: "none",
                          boxShadow: "none",
                          backgroundColor: "#fff",
                          borderColor: "#fff",
                        },
                      },
                      "&:nth-of-type(2)": {
                        backgroundColor: "#0bd7ee",
                        color: "#000",
                        borderColor: "#0bd7ee",
                        textTransform: "unset !important",
                        fontSize: "0.8rem",
                        "&:hover, &,focus": {
                          outline: "none",
                          boxShadow: "none",
                          backgroundColor: "#0bd7ee",
                          borderColor: "#0bd7ee",
                        },
                      },
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                    sx={{ mt: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    sx={{ mt: 1 }}
                    disabled={props.saveProgramLoading}
                  >
                    {id != "new" ? "Update" : "Save"}
                  </Button>
                </Box>
              </Box>
            </div>
            <Box
              sx={{ padding: 2 }}
              style={{ display: activeTab === 1 ? "block" : "none" }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: fullHtmlContent }}
                style={{ border: "1px solid #ddd", padding: "10px" }}
              />
            </Box>
          </Box>
        </Box>
      </Hoc>
    </Box>
  );
};

AddProgram.propTypes = {};
AddProgram.defaultProps = {};

const actionCreators = {
  showDetail,
  createProgram,
  getProgramDetails,
  updateProgram,
  uploadFile,
};
const AddProgramWrapper = {
  ".menu-item-container": {
    "& p": {
      color: "#fff",
      opacity: 1,
    },
  },
};
const PrgmSubs = {
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
  width: "100%",
  overflowX: "auto",
  backgroundColor: "rgb(15, 28, 30)",
  border: "1px solid rgb(39, 51, 52)",
  color: "rgb(255, 255, 255)",
  margin: "10px 20px",
  ".FieldsWrap": {
    ".MuiGrid-root ": {
      ".MuiBox-root": {
        borderBottom: "1px solid rgb(89 89 89 / 17%)",
        label: {
          fontSize: "0.8rem",
          textTransform: "uppercase",
        },
        ".MuiFormControl-root": {
          m: "0px !important",
          ".MuiInputBase-root": {
            fieldset: {
              display: "none",
            },
          },
          ".MuiFormLabel-root": {
            "&.MuiInputLabel-shrink": {
              color: "#fff",
            },
          },
        },
      },
    },
  },
};

const EditorWrapper = {
  ".MuiTabs-root": {
    ".MuiTabs-flexContainer": {
      "button[aria-selected='true']": {
        color: "#fff",
        opacity: 1,
      },
      button: {
        color: "#fff",
        opacity: 0.7,
      },
    },
    ".MuiTabs-indicator": {
      backgroundColor: "#fff",
    },
  },
  ".quill ": {
    ".ql-toolbar": {
      ".ql-formats": {
        ".ql-font ": {
          ".ql-picker-label": {
            color: "#fff",
          },
        },
        ".ql-picker": {
          ".ql-picker-label": {
            color: "#fff",
          },
          ".ql-picker-options": {
            ".ql-picker-item": {
              color: "#000",
            },
            svg: {
              line: {
                stroke: "#000",
              },
            },
          },
        },
        "&:nth-of-type(1)": {
          button: {
            svg: {
              path: {
                stroke: "unset",
                fill: "#fff",
              },
            },
          },
        },
        "&:nth-of-type(2)": {
          svg: {
            polyline: {
              fill: "#fff",
              stroke: "#fff",
            },
          },
        },
        "&:nth-of-type(4)": {
          svg: {
            polyline: {
              fill: "unset",
              stroke: "#fff",
            },
            line: {
              opacity: 1,
            },
          },
          ".ql-background": {
            svg: {
              g: {
                "polygon, rect, path": {
                  stroke: "#ffffff7d",
                },
              },
              // polyline:{
              //   fill: "unset",
              //   stroke: "#fff",
              // }
            },
          },
        },
        "&:nth-of-type(7)": {
          button: {
            "&:nth-of-type(2)": {
              svg: {
                polyline: {
                  stroke: "#fff",
                },
              },
            },
          },
        },
      },

      svg: {
        path: {
          stroke: "#fff",
        },
        g: {
          path: {
            fill: "#fff",
            stroke: "#fff",
          },
        },
        "polygon, line, rect": {
          stroke: "#fff",
        },
        polyline: {
          fill: "#fff",
        },
      },
    },
    ".ql-container": {
      ".ql-editor ": {
        minHeight: "100px",
      },
    },
  },
};
export default connect(({ user }) => user, actionCreators)(AddProgram);

const generateHtml = (formData, editorContents) => {
  return `
    <div class="program-content">
      <div>
        <button 
          class="buy-now-button"
          style="width: 100%; padding: 10px; font-size: 16px; font-weight: bold; background-color: #e29c2e; pointer-events: auto;">
          Buy Now For ${formData.amount} ${formData.currency?.toUpperCase()} (${formData.duration
    } ${formData.unit && capitalizeFirstLetter(formData.unit) + "s"})
        </button>
      </div>
      <div style="padding-top: 20px; overflow-y: auto; max-height: 80vh;">
        <h1 style="font-weight: 600; text-align: center;">${formData.name || "Program Name"
    }</h1>
        <p style="font-size: 14px; color: gray; text-align: center;">${formData.description || "Description goes here..."
    }</p>
        <div class="ql-snow">
        <div class="ql-editor">

          ${editorContents &&
    editorContents.map((content) => `<div>${content}</div>`).join("")
    }
              </div>
        </div>
      </div>
    </div>
  `;
};
