import React, {
  useState,
  useEffect,
  Fragment,
  forwardRef,
} from "react";
import { AppBar, Box, Grid, IconButton, Typography } from "@mui/material";

import "./userdetails.scss";

import { connect } from "react-redux";

import { showDetail } from "../../redux/action/user";
import { userProfileModalToggle, modelToggle } from "../../redux/action/user";
import AboutTab from "./About/about";
import VideoTab from "./video";
import AnalysisTab from "./Analysis";
import UserRemove from "./UserRemove";
import ViewData from "./About/ViewData";
import UserScore from "./About/UserScore";
import PaymentHistory from "./About/PaymentHistory";
import AssessementProgress from "./About/AssessmentQues/assessmentProgress";

const UserDetails = forwardRef((props, ref) => {
  const [activeTab, setActiveTab] = useState("About");
  const [userRole, setUserRole] = useState();
  const [activeAboutInfo, setActiveAboutInfo] = useState("aboutDetails");

  useEffect(() => {
    const user = localStorage.getItem("userRole");
    setUserRole(user);
  }, [localStorage.getItem("userRole")]);

  const aboutSection = {
    aboutDetails: (
      <AboutTab
        userData={props?.data?.contentUser}
        userProfile={props?.data?.contentUserProfile}
        setActiveAboutInfo={setActiveAboutInfo}
        role={userRole}
        ref={ref}
      />
    ),

    viewData: <ViewData setActiveAboutInfo={setActiveAboutInfo} ref={ref} />,
    reviewData: <UserScore setActiveAboutInfo={setActiveAboutInfo} ref={ref} />,
    payHistory: (
      <PaymentHistory setActiveAboutInfo={setActiveAboutInfo} ref={ref} />
    ),
    assessment: (
      <AssessementProgress setActiveAboutInfo={setActiveAboutInfo} ref={ref} />
    ),
  };
  return (
    <div
      className="user-details-container p-0"
      style={{ position: "relative" }}
    >
      <div>
        <UserRemove activePage={props.activePage} role={userRole} />

        <AppBar
          position="relative"
          sx={{ backgroundColor: "#1e777e", padding: "10px" }}
        >
          <Grid container direction="row" alignItems="center" spacing={1}>
            <ul className="inner-user-ul-list">
              <li
                onClick={() => {
                  setActiveTab("About");
                  setActiveAboutInfo("aboutDetails");
                }}
                className={activeTab === "About" ? "active-tabe" : ""}
              >
                <Grid item>
                  {" "}
                  <svg
                    className="cus-svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.68 3.96C16.16 4.67 16.44 5.52 16.44 6.44C16.43 8.84 14.54 10.79 12.16 10.87C12.06 10.86 11.94 10.86 11.83 10.87C9.61996 10.8 7.82996 9.11 7.58996 6.95C7.29996 4.38 9.40996 2 11.99 2"
                      stroke={activeTab === "About" ? "#727A7B" : "#fff"}
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.99005 14.56C4.57005 16.18 4.57005 18.82 6.99005 20.43C9.74005 22.27 14.25 22.27 17 20.43C19.42 18.81 19.42 16.17 17 14.56C14.27 12.73 9.76005 12.73 6.99005 14.56Z"
                      stroke={activeTab === "About" ? "#727A7B" : "#fff"}
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid item> About</Grid>
              </li>
              {userRole === "3" && (
                <li
                  onClick={() => setActiveTab("Video")}
                  className={activeTab === "Video" ? "active-tabe" : ""}
                >
                  {userRole === "3" && (
                    <Grid item>
                      <svg
                        className="cus-svg"
                        width="24"
                        height="24"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.6667 26.6668H9.33341C5.33341 26.6668 2.66675 24.0002 2.66675 20.0002V12.0002C2.66675 8.00016 5.33341 5.3335 9.33341 5.3335H22.6667C26.6667 5.3335 29.3334 8.00016 29.3334 12.0002V20.0002C29.3334 24.0002 26.6667 26.6668 22.6667 26.6668Z"
                          stroke={activeTab === "Video" ? "#727A7B" : "#fff"}
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.2 12.6669L18.5334 14.6669C19.7334 15.4669 19.7334 16.6669 18.5334 17.4669L15.2 19.4669C13.8667 20.2669 12.8 19.6002 12.8 18.1336V14.1336C12.8 12.4002 13.8667 11.8669 15.2 12.6669Z"
                          stroke={activeTab === "Video" ? "#727A7B" : "#fff"}
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Grid>
                  )}
                  {userRole === "3" && (
                    <Grid item onClick={() => setActiveTab("Video")}>
                      {" "}
                      Video
                    </Grid>
                  )}
                </li>
              )}
              <li
                onClick={() => setActiveTab("Analysis")}
                className={activeTab === "Analysis" ? "active-tabe" : ""}
              >
                {userRole !== "1" && (
                  <Fragment>
                    <Grid item>
                      <svg
                        className="cus-svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.32 12.0002C20.92 12.0002 22 11.0002 21.04 7.72018C20.39 5.51018 18.49 3.61018 16.28 2.96018C13 2.00018 12 3.08018 12 5.68018V8.56018C12 11.0002 13 12.0002 15 12.0002H18.32Z"
                          stroke={activeTab === "Analysis" ? "#727A7B" : "#fff"}
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.9999 14.6998C19.0699 19.3298 14.6299 22.6898 9.57993 21.8698C5.78993 21.2598 2.73993 18.2098 2.11993 14.4198C1.30993 9.38977 4.64993 4.94977 9.25993 4.00977"
                          stroke={activeTab === "Analysis" ? "#727A7B" : "#fff"}
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Grid>
                    <Grid item> Analysis</Grid>
                  </Fragment>
                )}
              </li>
            </ul>
          </Grid>
        </AppBar>
      </div>
      <div
        style={{
          overflow: "auto",
          height: "calc(100vh - 290px)",
          paddingTop: "10px",
        }}
      >
        {activeTab === "About" && aboutSection[activeAboutInfo]}
        {activeTab === "Video" && <VideoTab />}
        {activeTab === "Analysis" && <AnalysisTab />}
      </div>
    </div>
  );
});

UserDetails.propTypes = {};
UserDetails.defaultProps = {};

const actionCreators = {
  showDetail,
  userProfileModalToggle,
  modelToggle,
};

export default connect(
  ({ user, content }, ref) => ({
    selectedUserData: user.selectedUser,
    selectedUserLoading: user.selectedUserLoading,
    userProfileModal: user.userProfileModalToggle,
    modelToggleData: user.modelToggle,
    userDetailList: user.userDetail,
    userDetailLoading: user.userDetailLoading,
  }),
  actionCreators,
  null,
  { forwardRef: true }
)(UserDetails);
