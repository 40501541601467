import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
} from "react";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { Box, InputLabel } from "@mui/material";
import { Chart } from "react-google-charts";
import {
  getSurveyList,
  setSelectedSurveyData,
} from "../../../redux/action/user";
import { ConvertUTCtoLocal } from "../../../common";

const ViewData = forwardRef((props, ref) => {
  useEffect(() => {
    props.getSurveyList();
  }, []);

  useImperativeHandle(ref, () => ({
    handleBackButton: () => props.setActiveAboutInfo("aboutDetails"),
  }));

  const review = async (selectedSurvey) => {
    if (selectedSurvey.Score !== 0) {
      props.setActiveAboutInfo("reviewData");
      props.setSelectedSurveyData(selectedSurvey);
    } else {
      props.setSelectedSurveyData(selectedSurvey);
      props.setActiveAboutInfo("assessment");
    }
  };

  const chartData = [["Date and Time", "Score", { role: "annotation" }]];
  props?.user?.surveyIdList &&
    props?.user?.surveyIdList?.length > 0 &&
    props?.user?.surveyIdList?.forEach((survey, index) => {
      const { dateTime } = ConvertUTCtoLocal(
        survey.CreationDate,
        survey.CreationTime
      );
      if (props.user.surveyIdList.length > 10) {
        if (index >= props.user.surveyIdList.length - 10) {
          chartData.push([
            `${dateTime}`,
            survey.Score === null ? 0 : survey.Score,
            survey.Score === null ? 0 : survey.Score,
          ]);
        }
      } else {
        chartData.push([
          `${dateTime}`,
          survey.Score === null ? 0 : survey.Score,
          survey.Score === null ? 0 : survey.Score,
        ]);
      }
    });

  return (
    <Fragment>
      <div
        style={{
          padding: "10px 5px 5px 5px",
          overflow: "auto",
          minHeight: "400px",
        }}
      >
        {props?.user?.surveyIdListLoading ? (
          <h1> Loading...</h1>
        ) : (
          props.user.surveyIdList &&
          props.user.surveyIdList.length > 0 && (
            <div style={{ paddingBottom: "30px" }}>
              <Box sx={{ ...ChartWrap }}>
                <Chart
                  width={"100%"}
                  height={"300px"}
                  chartType="LineChart"
                  loader={<div>Loading Assessment Data</div>}
                  data={chartData}
                  options={{
                    backgroundColor: { fill: "transparent" },
                    colors: ["#000"],
                    title: "My Progress",
                    hAxis: {
                      title: "Date and Time",
                    },
                    vAxis: {
                      title: "Score",
                    },
                    titleTextStyle: {
                      color: "#000",
                    },
                    isStacked: true,
                  }}
                  rootProps={{ "data-testid": "1" }}
                  backgroundColor="transparent"
                />
              </Box>
            </div>
          )
        )}
        <TableContainer
          sx={{
            border: "2px solid #e7fbfd",
            borderRadius: "15px",
            backgroundColor: "#e7fbfd",
            p: 1,
            width: "auto",
            table: {
              tbody: {
                tr: {
                  td: {
                    "&:last-child": {
                      label: {
                        "&:hover": {
                          color: "#e29c2e !important",
                        },
                      },
                    },
                  },
                },
              },
            },
          }}
        >
          <Table
            sx={{ backgroundColor: "transparent" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: "#e29c2e",
                    fontWeight: 800,
                    fontSize: "17px",
                  }}
                >
                  Assesment
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "#e29c2e",
                    fontWeight: 800,
                    fontSize: "17px",
                  }}
                >
                  Assesment Date
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "#e29c2e",
                    fontWeight: 800,
                    fontSize: "17px",
                  }}
                >
                  Assesment Time
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "#e29c2e",
                    fontWeight: 800,
                    fontSize: "17px",
                  }}
                >
                  Vitality Score
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: "#e29c2e",
                    fontWeight: 800,
                    fontSize: "17px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.user.surveyIdList &&
                props.user.surveyIdList.length > 0 &&
                props.user.surveyIdList.map((row) => {
                  const { date, time } = ConvertUTCtoLocal(
                    row.CreationDate,
                    row.CreationTime
                  );
                  return (
                    <TableRow
                      key={row.SurveyId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ color: "whitesmoke" }}
                      >
                        {`Assessement - ${row.SurveyId + 1}`}
                      </TableCell>
                      <TableCell align="center" style={{ color: "whitesmoke" }}>
                        {date}
                      </TableCell>
                      <TableCell align="center" style={{ color: "whitesmoke" }}>
                        {time}
                      </TableCell>
                      <TableCell align="center" style={{ color: "whitesmoke" }}>
                        {row.Score === 0 || row.Score === null
                          ? "In Progress"
                          : row.Score}
                      </TableCell>
                      <TableCell align="center" style={{ color: "whitesmoke" }}>
                        <InputLabel
                          style={{
                            cursor: "pointer",
                            color: "whitesmoke",
                            paddingRight: "20px",
                          }}
                          onClick={() => review(row)}
                        >
                          {`${
                            row.Score === 0 || row.Score === null
                              ? "Continue"
                              : "View"
                          }`}
                        </InputLabel>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
});

ViewData.propTypes = {};
ViewData.defaultProps = {};

const actionCreators = {
  getSurveyList,
  setSelectedSurveyData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators,
  null,
  { forwardRef: true }
)(ViewData);

const ChartWrap = {
  border: "2px solid #e7fbfd",
  borderRadius: "15px",
  backgroundColor: "#e7fbfd",
  p: 1,
  svg: {
    "&>g": {
      rect: {
        fill: "#000",
      },
      text: {
        fill: "#000",
        fontSize: "0.875rem",
        "&:hover": {
          fill: "rgb(235 180 93)",
        },
      },
      "&:nth-of-type(1)": {
        text: {
          fill: "rgb(235 180 93)",
          fontWeight: 800,
          fontSize: "17px",
        },
      },
      "&:last-child": {
        text: {
          fill: "#000",
        },
      },
    },
  },
};
