import React, { useState } from "react";
import "./Program.scss";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import Hoc from "../../component/HOC";
import BaseTable from "../../component/BaseTable/BaseTable";
import { Visibility, Edit, Delete } from "@mui/icons-material";
import { Box, IconButton, Modal, Switch } from "@mui/material";
import {
  getProgramList,
  getUser,
  updateProgram,
} from "../../redux/action/user";
import closex from "../../assets/images/x.png";
import { capitalizeFirstLetter, decodeHtmlEntities } from "../../common";
import "react-quill/dist/quill.snow.css";

const ProgramSubscriptionListing = (props) => {
  const navigate = useNavigate();
  const list = props.programList || {};
  const [rowData, setRowData] = useState(null);
  const [view, setView] = useState(false);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [paginationParams, setPaginationParams] = useState({
    currentPage: list?.pagination?.currentPage || 1,
    limit: list?.pagination?.pageSize || 10,
    total: list?.pagination?.totalPages || 1,
    totalRecords: list?.pagination?.totalUsers || 0,
  });
  const [filters, setFilters] = useState(filterData);

  const handleFilterClick = (updatedFilters) => {
    setFilters(updatedFilters);
  };

  const handleToggle = (rowId) => {
    setExpandedRowId((prevExpandedRowId) =>
      prevExpandedRowId === rowId ? null : rowId
    );
  };

  const handleProgramToggle = async (data) => {
    const formData1 = new URLSearchParams();
    formData1.append("ID", data.ID || "");
    formData1.append("status", data.status == "1" ? "0" : "1" || "");

    await props.updateProgram(formData1);
    props.getProgramList();
  };

  console.log('programList', list.programList)
  const renderRows = () => {
    return (
      list.programList &&
      list.programList.length > 0 &&
      list.programList?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((row, index) => (
        <TableRow
          key={row.ID}
          // onClick={() => UserDetails(row?.UserID, true, 0)}
          className="table-row"
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            "& th": {
              color: "#fff",
              borderBottom: "1px solid #273334",
            },
            "& td": {
              borderBottom: "1px solid #273334",
              "&:nth-of-type(3)": {
                width: "40%",
              },
            },
          }}
        >
          <TableCell
            component="th"
            scope="row"
            align="left"
            className="text-white"
          >
            <div className="d-flex overEffect align-items-center">
              <Avatar
                className="mx-3"
                alt="program"
                src={row?.imageUrl && row?.imageUrl}
                style={{ borderRadius: "unset" }}
              />
              <div>
                <span className="nameText">{row.name}</span>
              </div>
            </div>
          </TableCell>

          <TableCell align="center" className="text-white">
            {`${row.amount} ${row.currency && row.currency.toUpperCase()}`}
          </TableCell>
          <TableCell align="center" className="text-white">
            {`${row.duration} ${(row.interval && capitalizeFirstLetter(row.interval) + "s") ||
              "Days"
              }`}
          </TableCell>
          <TableCell align="center" className="text-white">
            {row.description && row.description.length > 40 ? (
              <>
                {/* Check if this row is expanded or not */}
                {expandedRowId === row.ID ? (
                  <>
                    {row.description}
                    <span
                      className="see-more"
                      onClick={() => handleToggle(row.ID)} // Toggle action
                      style={{
                        cursor: "pointer",
                        color: "#fff",
                        textDecoration: "underline",
                        paddingLeft: "5px",
                      }}
                    >
                      See less
                    </span>
                  </>
                ) : (
                  <>
                    {row.description.slice(0, 40)}...
                    <span
                      className="see-more"
                      onClick={() => handleToggle(row.ID)} // Toggle action
                      style={{
                        cursor: "pointer",
                        color: "#fff",
                        textDecoration: "underline",
                        paddingLeft: "5px",
                      }}
                    >
                      See more
                    </span>
                  </>
                )}
              </>
            ) : (
              // If description is less than 40 chars, just display the full description
              row.description
            )}
          </TableCell>
          <TableCell align="center" className="text-white">
            <IconButton
              onClick={() => {
                setRowData(row);
                setView(true);
              }}
              sx={sxIcon}
            >
              <Visibility />
            </IconButton>

            <IconButton
              onClick={() => navigate(`/programs/${row.ID}`)}
              sx={sxIcon}
            >
              <Edit />
            </IconButton>

            {/* <IconButton onClick={() => true} sx={sxIcon}>
            <Delete />
          </IconButton> */}

            <Switch
              checked={row.status == 1 ? true : false}
              onChange={() => handleProgramToggle(row)}
              color="primary"
            />
          </TableCell>
        </TableRow>
      ))
    );
  };

  return (
    <Hoc activeMenu="Program & Subscriptions">
      <div>
        <BaseTable
          headers={headers}
          renderRows={renderRows}
          fetchData={props.getProgramList}
          isLoading={props.ProgramListLoading}
          height={"390px"}
          showPagination={paginationParams.total > 0}
          paginationParams={paginationParams}
          setPaginationParams={setPaginationParams}
          currentPage={paginationParams.currentPage}
          limit={paginationParams.limit}
          handleFilterClick={handleFilterClick}
          filters={filters}
          isFilter={true}
        />
      </div>
      <Modal open={view} onClose={() => setView(false)}>
        <Box sx={modalStyle}>
          <div
            className="close-pop-wrap"
            style={{ textAlign: "right", padding: "10px", position: "sticky" }}
          >
            <img
              src={closex}
              alt="Close"
              onClick={() => setView(false)}
              style={{ cursor: "pointer", width: "24px", height: "24px" }}
            />
          </div>
          <div>
            {rowData && rowData.summary ? (
              <HtmlContent rawHtmlContent={rowData.summary} />
            ) : (
              <h4 align="center">No Data Found</h4>
            )}
          </div>
        </Box>
      </Modal>
    </Hoc>
  );
};

ProgramSubscriptionListing.propTypes = {};
ProgramSubscriptionListing.defaultProps = {};

const actionCreators = {
  getUser,
  getProgramList,
  updateProgram,
};

export default connect(
  ({ user }) => user,
  actionCreators
)(ProgramSubscriptionListing);

const modalStyle = {
  overflow: "hidden",
  position: "relative",
  // height: "80vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90vh",
  backgroundColor: "white",
  boxShadow: 24,
  padding: "20px",
  // overflowY: "auto", // Makes the content scrollable
  borderRadius: "8px",
};

const sxIcon = {
  svg: {
    path: {
      fill: "#fff",
      color: "#fff",
    },
  },
};

const HtmlContent = ({ rawHtmlContent }) => {
  // Decode the raw HTML content
  const decodedHtml = decodeHtmlEntities(rawHtmlContent);

  return <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />;
};

const headers = [
  { label: "Name", align: "center" },
  { label: "Amount", align: "center" },
  { label: "Validity", align: "center" },
  { label: "Description", align: "center" },
  { label: "Actions", align: "center" },
];

const filterData = [
  { id: 1, label: "Name", value: "", type: "input", name: "name" },
  { id: 2, label: "Amount", value: "", type: "input", name: "amount" },
  {
    id: 3,
    label: "Description",
    value: "",
    type: "input",
    name: "description",
  },
  { id: 4, label: "Validity", value: "", type: "input", name: "validity" },
  {
    id: 5,
    label: "Status",
    name: "status",
    value: "",
    type: "multi-select",
    dropArr: [
      {
        value: "Active",
        label: "Active",
      },
      {
        value: "Deactive",
        label: "Deactive",
      },
    ],
  },
];
